<template>
  <v-dialog persistent v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-card class="px-7">
      <v-card-title class="px-0 pt-9 pb-5">
        <h1 class="title font-weight-regular">{{ modalTitle }}</h1>
        <v-btn class="ml-auto mr-n1" small text icon color="black" @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="update-info-modal__content pa-0">
        <slot name="modal-body"></slot>
      </v-card-text>

      <v-card-actions class="py-7">
        <slot name="action-button">
          <v-btn
            v-if="shouldShowActionButton"
            class="white--text"
            :color="actionButtonColor"
            :loading="loading"
            tile
            large
            @click="onActionButtonClick"
          >
            {{ actionButtonLabel }}
          </v-btn>
        </slot>
        <v-btn
          v-if="hasCloseButton"
          class="ml-5"
          color="light-blue darken-4"
          text
          @click="onCancelClick"
        >
          {{ closeButtonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_BUTTON_COLOR } from '@/constants/common';

export default {
  name: 'UpdateInfoModal',
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    actionButtonLabel: {
      type: String,
      default: ''
    },
    closeButtonLabel: {
      type: String,
      default: 'Cancel'
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    actionButtonColor: {
      type: String,
      default: DEFAULT_BUTTON_COLOR
    },
    loading: {
      type: Boolean,
      default: false
    },
    shouldShowActionButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    onActionButtonClick() {
      this.$emit('action-button-click');
    },
    onCancelClick() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.update-info-modal {
  &__content {
    max-height: 710px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
}
</style>
