<template>
  <update-info-modal
    v-model="isModalShown"
    width="800"
    scrollable
    :action-button-label="actionButtonLabel"
    :loading="isLoading"
    :modal-title="modalTitle"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="userInfoForm" :key="isModalShown" class="pt-7 pr-4 pb-5 pl-1">
        <user-demo-info
          :user-id="userInfo.id"
          :value="userDemoInfo"
          :is-edit-mode="editUserMode"
          @input="onUserDemoInfoChange"
        />
        <v-divider class="my-5" />
        <user-main-info
          v-model="userMainInfo"
          :display-password-field="!editUserMode"
          :login-error="loginError"
          :password-error="passwordError"
        />
        <v-divider class="mt-5 mb-7" />
        <user-additional-info
          v-model="userAdditionalInfo"
          :is-edit-mode="editUserMode"
          :is-webshop-user="isWebshopUser"
          :is-select-state-visible="isSelectStateVisible"
          :should-show-logistic-center-country-error="shouldShowLogisticCenterCountryError"
        />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { pick, clone, omit, path, pathOr } from 'ramda';
import { mapGetters } from 'vuex';

import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import UserMainInfo from '@/components/user/UserMainInfo.vue';
import UserDemoInfo from '@/components/user/UserDemoInfo.vue';
import UserAdditionalInfo from '@/components/user/UserAdditionalInfo.vue';

import { createUser, updateUser, updateDoctorSignature } from '@/api/users.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR } from '@/constants/common';
import types from '@/store/types';
import { STATUS_CODES } from '@/constants/errors';
import { COUNTRY_ISO } from '@/constants/countries';

const USER_DEMO_INFO_FIELD_NAMES = [
  'role',
  'demoAccount',
  'doctorLevel',
  'disablePFEN',
  'microneedlingEnabled',
  'multiCountrySellingAvailable',
  'medicalConcentrationBlocked',
  'autopilotEnabled'
];
const USER_MAIN_INFO_FIELD_NAMES = ['username', 'password', 'name', 'email'];
const USER_ADDITIONAL_INFO_FIELD_NAMES = [
  'locale',
  'country',
  'doctorSlug',
  'customSMSLimit',
  'personalPage',
  'universkinLink',
  'consultationCost',
  'privatePhone',
  'privatePhoneIso',
  'officePhone',
  'officePhoneIso',
  'secondaryEmails'
];

const USER_DEMO_DEFAULT_FIELDS = {
  role: null,
  doctorLevel: null,
  demoAccount: false,
  disablePFEN: false,
  microneedlingEnabled: false,
  multiCountrySellingAvailable: false,
  medicalConcentrationBlocked: false,
  autopilotEnabled: false
};

const USER_MAIN_INFO_DEFAULT_FIELDS = {
  username: '',
  password: '',
  name: '',
  email: ''
};

const USER_ADDITIONAL_DEFAULT_FIELDS = {
  locale: null,
  country: null,
  state: null,
  doctorSlug: null,
  customSMSLimit: 125,
  personalPage: null,
  universkinLink: null,
  consultationCost: null,
  privatePhone: null,
  privatePhoneIso: null,
  officePhone: null,
  officePhoneIso: null,
  secondaryEmails: []
};

const USER_FIELDS_TO_OMIT_IN_EDIT_MODE = ['password', 'universkinLink'];

const LOGIN_ERROR = 'LOGIN_ALREADY_IN_USE';
const LOGIN_ERROR_MESSAGE = 'Login already in use';
const PASSWORD_ERROR = 'WRONG_PASSWORD';
const PASSWORD_ERROR_MESSAGE = 'Invalid password';

export default {
  name: 'UpdateUserInfoModal',
  components: { UpdateInfoModal, UserAdditionalInfo, UserDemoInfo, UserMainInfo },
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    editUserMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalShown: false,
      isLoading: false,
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      userDemoInfo: { ...USER_DEMO_DEFAULT_FIELDS },
      userMainInfo: { ...USER_MAIN_INFO_DEFAULT_FIELDS },
      loginError: '',
      passwordError: '',
      userAdditionalInfo: { ...USER_ADDITIONAL_DEFAULT_FIELDS },
      shouldShowLogisticCenterCountryError: false
    };
  },
  computed: {
    ...mapGetters({
      userId: types.getters.USER_ID
    }),
    modalTitle() {
      return this.editUserMode ? 'Edit user info' : 'New user';
    },
    actionButtonLabel() {
      return this.editUserMode ? 'Edit user' : 'Create user';
    },
    isWebshopUser() {
      return this.userDemoInfo.doctorLevel > 1;
    },
    isSelectStateVisible() {
      return this.userAdditionalInfo.country === COUNTRY_ISO.UNITED_STATES;
    }
  },
  watch: {
    userInfo() {
      if (!this.editUserMode) {
        return;
      }

      this.getInfoFromUser();
    }
  },
  methods: {
    onUserDemoInfoChange(userDemoInfo) {
      const {
        doctorLevel,
        disablePFEN: disablePFENSelected,
        autopilotEnabled: autopilotEnabledSelected
      } = userDemoInfo;

      const {
        disablePFEN: disablePFENDefault,
        autopilotEnabled: autopilotEnabledDefault
      } = USER_DEMO_DEFAULT_FIELDS;

      const disablePFEN = (doctorLevel === 1) || (doctorLevel === 2) ? disablePFENSelected : disablePFENDefault;
      const autopilotEnabled = doctorLevel > 1 ? autopilotEnabledSelected : autopilotEnabledDefault;

      this.userDemoInfo = { ...userDemoInfo, disablePFEN, autopilotEnabled };
    },
    onModalClose() {
      this.isModalShown = false;

      if (this.editUserMode) {
        this.getInfoFromUser();
        return;
      }

      this.userDemoInfo = { ...USER_DEMO_DEFAULT_FIELDS };
      this.userMainInfo = { ...USER_MAIN_INFO_DEFAULT_FIELDS };
      this.userAdditionalInfo = { ...USER_ADDITIONAL_DEFAULT_FIELDS };
    },
    getInfoFromUser() {
      const userInfoCopy = clone(this.userInfo);

      const userDemoInfo = pick(USER_DEMO_INFO_FIELD_NAMES, userInfoCopy);
      const userMainInfo = pick(USER_MAIN_INFO_FIELD_NAMES, userInfoCopy);
      const userAdditionalInfo = pick(USER_ADDITIONAL_INFO_FIELD_NAMES, userInfoCopy);

      this.userDemoInfo = { ...USER_DEMO_DEFAULT_FIELDS, ...userDemoInfo };
      this.userMainInfo = { ...USER_MAIN_INFO_DEFAULT_FIELDS, ...userMainInfo };
      this.userAdditionalInfo = { ...USER_ADDITIONAL_DEFAULT_FIELDS, ...userAdditionalInfo };
    },
    async onActionButtonClick() {
      this.loginError = '';
      this.passwordError = '';

      const { userInfoForm } = this.$refs;

      if (!userInfoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      try {
        const consultationCost = this.isWebshopUser
          ? parseInt(this.userAdditionalInfo.consultationCost, 10)
          : null;

        const { logisticCenter } = this.userInfo;

        const userInfo = {
          ...this.userDemoInfo,
          ...this.userMainInfo,
          ...this.userAdditionalInfo,
          customSMSLimit: parseInt(this.userAdditionalInfo.customSMSLimit, 10),
          consultationCost,
          logisticCenter
        };

        if (this.editUserMode) {
          const editUserInfo = omit(USER_FIELDS_TO_OMIT_IN_EDIT_MODE, userInfo);

          try {
            await updateUser(this.userInfo.id, editUserInfo);

            const { id: editDoctorId } = this.userInfo;
            const { name: editDoctorName } = this.userMainInfo;

            await updateDoctorSignature({ doctorId: editDoctorId, doctorName: editDoctorName });

            this.onModalClose();
            this.$emit('update-user');
          } catch (error) {
            const status = pathOr(null, ['response', 'status'], error);

            this.shouldShowLogisticCenterCountryError =
              status === STATUS_CODES.UNPROCESSABLE_ENTITY;
          }

          return;
        }

        const { data: doctorId } = await createUser({ ...userInfo });
        const { name: doctorName } = userInfo;

        await updateDoctorSignature({ doctorId, doctorName });

        this.onModalClose();
        this.$emit('create-user');
      } catch (error) {
        const requestError = path(['response', 'data', 'description'], error);

        if (requestError === LOGIN_ERROR) {
          this.loginError = LOGIN_ERROR_MESSAGE;
        }

        if (requestError === PASSWORD_ERROR) {
          this.passwordError = PASSWORD_ERROR_MESSAGE;
        }

        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.clinics-info-column {
  &__clinic {
    padding: 10px;
  }
}
</style>
