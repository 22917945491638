<template>
  <v-select
    :menu-props="{ offsetY: true }"
    outlined
    dense
    hide-details="auto"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'BaseSelect'
};
</script>
