<template>
  <div class="d-flex justify-end flex-wrap mx-6">
    <v-text-field
      class="filter-box flex-grow-0"
      label="Search"
      prepend-icon="mdi-magnify"
      single-line
      clearable
      hide-details
      :value="value.searchString"
      @input="updateInputField($event, 'searchString')"
    />
    <v-select
      class="search-by-box ml-3 flex-grow-0"
      label="Search By"
      :items="searchFields"
      :value="value.searchField"
      single-line
      return-object
      @change="updateInputField($event, 'searchField')"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchComponent',
  props: {
    searchFields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchField: this.searchFields[0]
    };
  },
  methods: {
    updateInputField(newValue, fieldName) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-box {
  width: 300px;
}

.search-by-box {
  width: 150px;
}
</style>
