import { countries } from 'countries-list';
import { pathOr } from 'ramda';

const CountriesService = {
  getPhoneCode: isoCode => pathOr('', [isoCode, 'phone'], countries),
  getCountryLabel: countryCode => pathOr('', [countryCode, 'name'], countries),
  getCountryISOByName: countryName => {
    const countriesList = Object.entries(countries);
    const country = countriesList.find(([, { name }]) => name === countryName );

    return pathOr('', [0], country);
  }
};

export default CountriesService;
