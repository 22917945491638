const LOCALE = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
  da: 'Danish',
  de: 'German',
  pl: 'Polish',
  ro: 'Romanian',
  nl: 'Dutch',
  lv: 'Latvian',
  lt: 'Lithuanian',
  sv: 'Swedish',
  el: 'Greek',
  ru: 'Russian',
  bg: 'Bulgarian',
  'es-MX': 'Mexican Spanish',
  ar: 'Arabic',
  tr: 'Turkish',
  uk: 'Ukrainian',
  it: 'Italian',
  'zh-CN': 'Chinese',
  zh: 'Simplified Chinese',
  pt: 'Portuguese',
  ka: 'Georgian',
  vi: 'Vietnamese'
};

export { LOCALE };
