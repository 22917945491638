import { countries } from 'countries-list';

import CountriesService from '@/services/CountriesService';

import { LOCALE } from '@/constants/locales';

const SORTING_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};

const FILE_TYPES = {
  PDF: 'application/pdf',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const DEFAULT_DATE_FORMAT = 'dd MMM yyyy';
const ISO_DATE_FORMAT = 'yyyy-MM-dd';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 20;

const VALIDATION_RULES = {
  REQUIRED: value => !!value || value === 0 || 'This field is required',
  COUNTRY: value =>
    !value || !!CountriesService.getCountryISOByName(value) || 'Country code not found',
  EMAIL: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value) || 'E-mail must be valid'
};

const DEFAULT_BUTTON_COLOR = 'light-blue darken-4';
const ERROR_BUTTON_COLOR = 'error';

const DEFAULT_CLINIC_ADDRESS_FIELDS = {
  address: '',
  building: '',
  apartment: '',
  city: '',
  zipCode: '',
  countryCode: '',
  stateCode: '',
  phone: '',
  phoneIso: 'AF',
  latitude: null,
  longitude: null
};

const DEFAULT_CLINIC_FIELDS = {
  clinicName: '',
  clinicAddress: { ...DEFAULT_CLINIC_ADDRESS_FIELDS },
  clinicDescription: '',
  clinicPage: ''
};

const DEFAULT_ADDRESS_FIELDS = {
  country: '',
  city: '',
  zip: '',
  street: '',
  building: '',
  apartment: ''
};

const DEFAULT_COORDINATES = { lat: 0, lng: 0 };

const COUNTRIES_LIST = Object.entries(countries).map(([code, { name }]) => ({ name, code }));

const COUNTRY_SELECT_ITEMS = COUNTRIES_LIST.map(({ name, code }) => ({
  text: name,
  value: code
}));

const LANGUAGE_SELECT_ITEMS = Object.entries(LOCALE).map(([locale, label]) => ({
  text: label,
  value: locale
}));

const DOCTOR_LEVEL = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3
};

export {
  SORTING_DIRECTIONS,
  DEFAULT_DATE_FORMAT,
  ISO_DATE_FORMAT,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  VALIDATION_RULES,
  DEFAULT_BUTTON_COLOR,
  ERROR_BUTTON_COLOR,
  DEFAULT_CLINIC_FIELDS,
  COUNTRIES_LIST,
  COUNTRY_SELECT_ITEMS,
  LANGUAGE_SELECT_ITEMS,
  DEFAULT_ADDRESS_FIELDS,
  DOCTOR_LEVEL,
  FILE_TYPES,
  DEFAULT_COORDINATES
};
