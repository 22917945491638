<template>
  <section class="update-user-form-layout pt-1">
    <base-text-field
      v-for="field in mainFormTextFields"
      :key="field.key"
      :value="value[field.fieldName]"
      v-bind="field"
      :error-messages="getLoginErrors(field.fieldName)"
      @change="updateInputField($event, field)"
    />
  </section>
</template>

<script>
import TextFieldConfiguration from '@/models/input-models/TextFieldConfiguration';
import BaseTextField from '@/components/common/BaseTextField.vue';

import { VALIDATION_RULES } from '@/constants/common';

const DEFAULT_MAIN_FORM_TEXT_FIELDS = [
  new TextFieldConfiguration({
    fieldName: 'username',
    label: 'Login *',
    rules: [VALIDATION_RULES.REQUIRED]
  }),
  new TextFieldConfiguration({
    fieldName: 'name',
    label: 'Name *',
    rules: [VALIDATION_RULES.REQUIRED]
  }),
  new TextFieldConfiguration({
    fieldName: 'email',
    label: 'Email *',
    rules: [VALIDATION_RULES.REQUIRED, VALIDATION_RULES.EMAIL]
  })
];

const PASSWORD_TEXT_FIELD = new TextFieldConfiguration({
  fieldName: 'password',
  type: 'password',
  label: 'Password *',
  rules: [VALIDATION_RULES.REQUIRED]
});

const PASSWORD_FIELD_INDEX = 1;

export default {
  name: 'UserMainInfo',
  components: { BaseTextField },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    displayPasswordField: {
      type: Boolean,
      default: true
    },
    loginError: {
      type: String,
      default: ''
    },
    passwordError: {
      type: String,
      default: ''
    }
  },
  computed: {
    mainFormTextFields() {
      if (this.displayPasswordField) {
        const defaultFields = [...DEFAULT_MAIN_FORM_TEXT_FIELDS];
        defaultFields.splice(PASSWORD_FIELD_INDEX, 0, PASSWORD_TEXT_FIELD);

        return defaultFields;
      }

      return DEFAULT_MAIN_FORM_TEXT_FIELDS;
    }
  },
  methods: {
    updateInputField(newValue, { fieldName }) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    },
    getLoginErrors(fieldName) {
      const errors = [];

      if (fieldName === 'username' && this.loginError !== '') {
        errors.push(this.loginError);
      }

      if (fieldName === 'password' && this.passwordError !== '') {
        errors.push(this.passwordError);
      }

      return errors;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/user/update-user-modal';
</style>
