<template>
  <header class="table-page-header d-flex justify-space-between align-center">
    <div class="title font-weight-regular">{{ title }}</div>
    <slot name="header-actions"></slot>
  </header>
</template>

<script>
export default {
  name: 'TablePageMainHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.table-page-header {
  min-height: 80px;

  padding: 0 30px 0 20px;
}
</style>
