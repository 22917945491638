<template>
  <v-text-field outlined dense hide-details="auto" v-bind="$attrs" v-on="$listeners">
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseTextField'
};
</script>
