<template>
  <main class="d-flex flex-column fill-height white">
    <table-page-main-header title="Admins&Doctors">
      <template v-slot:header-actions>
        <div class="d-flex justify-space-between fill-width">
          <search-component
            v-model="searchOptions"
            :search-fields="$options.availableSearchFields"
          />
          <div class="d-flex my-2 justify-end flex-wrap">
            <v-icon class="light-blue--text text--darken-4" :size="21">mdi-settings-outline</v-icon>
            <v-btn
              tile
              outlined
              large
              color="light-blue darken-4"
              class="white--text"
              min-width="166"
            >
              Report
            </v-btn>
            <update-user-info-modal @create-user="updateUsers">
              <template v-slot:activator="{ on }">
                <v-btn
                  tile
                  large
                  color="light-blue darken-4"
                  class="white--text ml-3"
                  min-width="166"
                  v-on="on"
                >
                  New User
                </v-btn>
              </template>
            </update-user-info-modal>
          </div>
        </div>
      </template>
    </table-page-main-header>
    <section class="flex-grow-1">
      <data-table
        :headers="$options.tableHeadersConfig"
        :items="users"
        :options.sync="options"
        :loading="isLoading"
        :items-per-page="100"
        :disable-pagination="isLoading"
        :server-items-length="serverItemsLength"
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <user-avatar :photo="item.photo" />
            <router-link
              class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
              :to="{ name: 'UserDetails', params: { id: item.id } }"
            >
              {{ item.name }}
            </router-link>
          </div>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import { equals } from 'ramda';

import { mapGetters } from 'vuex';

import DataTable from '@/components/common/DataTable.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import UpdateUserInfoModal from '@/components/user/UpdateUserInfoModal.vue';
import TablePageMainHeader from '@/components/common/TablePageMainHeader.vue';
import SearchComponent from '@/components/common/SearchComponent.vue';

import CountriesService from '@/services/CountriesService';

import { getUsersList } from '@/api/users.api';

import { getLabelFromBoolean, transformDataTableOptionsForRequest, debounceCall } from '@/utils';

import { USER_ROLE, USER_ROLE_LABEL } from '@/constants/user-roles';
import { SORTING_DIRECTIONS } from '@/constants/common';

import types from '@/store/types';

const debounce500ms = debounceCall(500);

const SUPER_ADMIN_VISIBLE_ROLES = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.DOCTOR];
const ADMIN_VISIBLE_ROLES = [USER_ROLE.DOCTOR];

const FIELD_NAMES = {
  NAME: 'name',
  USERNAME: 'username',
  ROLE: 'roleLabel',
  IS_DEMO: 'demoAccount',
  LEVEL: 'doctorLevel',
  MICRONEEDLING: 'microneedlingEnabled',
  MEDICAL_CONCENTRATION: 'medicalConcentrationBlocked',
  EMAIL: 'email',
  COUNTRY: 'country',
  ID: 'Id'
};

const FIELD_NAMES_FOR_PARAMS = {
  [FIELD_NAMES.NAME]: 'NAME',
  [FIELD_NAMES.USERNAME]: 'USERNAME',
  [FIELD_NAMES.ROLE]: 'ROLE',
  [FIELD_NAMES.IS_DEMO]: 'IS_DEMO',
  [FIELD_NAMES.LEVEL]: 'LEVEL',
  [FIELD_NAMES.MICRONEEDLING]: 'MICRONEEDLING',
  [FIELD_NAMES.EMAIL]: 'EMAIL',
  [FIELD_NAMES.COUNTRY]: 'COUNTRY',
  [FIELD_NAMES.ID]: 'ID'
};

const SEARCH_FIELDS = [
  { value: FIELD_NAMES_FOR_PARAMS[FIELD_NAMES.NAME], text: 'Name' },
  { value: FIELD_NAMES_FOR_PARAMS[FIELD_NAMES.USERNAME], text: 'Username' },
  { value: FIELD_NAMES_FOR_PARAMS[FIELD_NAMES.EMAIL], text: 'Email' },
  { value: FIELD_NAMES_FOR_PARAMS[FIELD_NAMES.ID], text: 'Id' }
];

const DEFAULT_SORT_BY_FIELD = FIELD_NAMES.NAME;

const USERS_TABLE_HEADER_CONFIG = [
  { text: 'Name', value: FIELD_NAMES.NAME },
  { text: 'Username', value: FIELD_NAMES.USERNAME, sortable: false },
  { text: 'Rights', value: FIELD_NAMES.ROLE },
  { text: 'Demo account', value: FIELD_NAMES.IS_DEMO },
  { text: "Doctor's level", value: FIELD_NAMES.LEVEL },
  { text: 'S.tep', value: FIELD_NAMES.MICRONEEDLING, sortable: false },
  { text: 'Email', value: FIELD_NAMES.EMAIL },
  { text: 'Country', value: FIELD_NAMES.COUNTRY }
];

export default {
  name: 'AdminsAndDoctorsPage',
  components: { SearchComponent, TablePageMainHeader, UpdateUserInfoModal, UserAvatar, DataTable },
  tableHeadersConfig: USERS_TABLE_HEADER_CONFIG,
  availableSearchFields: SEARCH_FIELDS,
  data() {
    return {
      isLoading: false,
      options: {},
      users: [],
      searchOptions: {
        searchString: '',
        searchField: SEARCH_FIELDS[0]
      },
      serverItemsLength: 0
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: types.getters.IS_SUPER_ADMIN
    })
  },
  watch: {
    options() {
      this.updateUsers();
    },
    searchOptions: {
      handler: 'onSearchQueryChange',
      deep: true
    }
  },
  methods: {
    onSearchQueryChange(newValue, oldValue) {
      if (!equals(newValue.searchField, oldValue.searchField) && !newValue.searchString) {
        return;
      }

      debounce500ms(this.updateUsers);
    },
    async updateUsers() {
      this.users = await this.getUsers();
    },
    async getUsers() {
      try {
        this.isLoading = true;

        const userListVisibleRoles = this.isSuperAdmin
          ? SUPER_ADMIN_VISIBLE_ROLES
          : ADMIN_VISIBLE_ROLES;

        const {
          page,
          sortType = DEFAULT_SORT_BY_FIELD,
          sortDirection,
          size
        } = transformDataTableOptionsForRequest(this.options, SORTING_DIRECTIONS.ASC);

        const requestParams = {
          lettersToSearch: this.searchOptions.searchString,
          searchIn: this.searchOptions.searchField.value,
          sortType: FIELD_NAMES_FOR_PARAMS[sortType],
          sortDirection,
          page,
          size,
          roles: userListVisibleRoles
        };

        const {
          data: { users, usersTotal }
        } = await getUsersList(requestParams);

        this.serverItemsLength = usersTotal;

        return this.transformUsersData(users);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    },
    transformUsersData(users) {
      return users.map(user => {
        const {
          role,
          demoAccount,
          country: countryLabel,
          microneedlingEnabled,
          medicalConcentrationBlocked
        } = user;

        return {
          ...user,
          roleLabel: USER_ROLE_LABEL[role],
          demoAccount: getLabelFromBoolean(demoAccount),
          microneedlingEnabled: getLabelFromBoolean(microneedlingEnabled),
          medicalConcentrationBlocked: getLabelFromBoolean(medicalConcentrationBlocked),
          country: CountriesService.getCountryLabel(countryLabel)
        };
      });
    }
  }
};
</script>
