<template>
  <section class="user-demo-info update-user-form-layout pt-1">
    <base-select
      v-if="shouldShowRightsSelector"
      :value="value.role"
      label="Rights *"
      :items="rightSelectOptions"
      :rules="[$options.validationRules.REQUIRED]"
      @input="updateInputField($event, 'role')"
    />

    <base-select
      class="user-demo-info__level-select"
      :value="value.doctorLevel"
      label="Doctor level *"
      :items="$options.doctorLevelSelectOptions"
      :rules="[$options.validationRules.REQUIRED]"
      @input="updateInputField($event, 'doctorLevel')"
    />

    <v-checkbox
      :input-value="value.demoAccount"
      class="mt-0 pt-0"
      label="Demo SkinXs account"
      color="black"
      hide-details="auto"
      @change="updateInputField($event, 'demoAccount')"
    />

    <v-checkbox
      class="mt-0 pt-0"
      :input-value="value.disablePFEN"
      :disabled="isDTCNDCheckboxDisabled"
      label="DTCND"
      color="black"
      hide-details="auto"
      @change="updateInputField($event, 'disablePFEN')"
    />

    <v-checkbox
      class="mt-0 pt-0"
      :input-value="value.microneedlingEnabled"
      label="S.tep"
      color="black"
      hide-details="auto"
      @change="updateInputField($event, 'microneedlingEnabled')"
    />
    <v-checkbox
      class="mt-0 pt-0"
      :input-value="value.multiCountrySellingAvailable"
      label="Multi Country Selling"
      color="black"
      hide-details="auto"
      @change="updateInputField($event, 'multiCountrySellingAvailable')"
    />

    <v-checkbox
      class="mt-0 pt-0"
      :input-value="value.medicalConcentrationBlocked"
      label="Lock medical grade concentration"
      color="black"
      hide-details="auto"
      @change="updateInputField($event, 'medicalConcentrationBlocked')"
    />

    <v-checkbox
      v-if="isAutopilotCheckboxVisible"
      class="mt-0 pt-0"
      :input-value="value.autopilotEnabled"
      label="Autopilot"
      color="black"
      hide-details="auto"
      @change="updateInputField($event, 'autopilotEnabled')"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseSelect from '@/components/common/BaseSelect.vue';

import types from '@/store/types';
import { DOCTOR_LEVEL, VALIDATION_RULES } from '@/constants/common';
import { USER_ROLE, USER_ROLE_LABEL } from '@/constants/user-roles';

const DOCTOR_LEVEL_SELECT_OPTIONS = [
  { text: '0', value: DOCTOR_LEVEL.ZERO },
  { text: '1', value: DOCTOR_LEVEL.ONE },
  { text: '2', value: DOCTOR_LEVEL.TWO },
  { text: '3', value: DOCTOR_LEVEL.THREE }
];

const SUPER_ADMIN_RIGHTS = Object.values(USER_ROLE);
const SUPER_ADMIN_EDIT_RIGHTS = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN];
const ADMIN_RIGHTS = [USER_ROLE.DOCTOR];

export default {
  name: 'UserDemoInfo',
  components: { BaseSelect },
  doctorLevelSelectOptions: DOCTOR_LEVEL_SELECT_OPTIONS,
  validationRules: VALIDATION_RULES,
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    isEditMode: {
      type: Boolean,
      required: true
    },
    userId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: types.getters.IS_SUPER_ADMIN
    }),
    shouldShowRightsSelector() {
      return !this.isEditMode || (this.isEditMode && this.isSuperAdmin);
    },
    rightSelectOptions() {
      let selectRights;

      if (this.isSuperAdmin) {
        selectRights = this.isEditMode ? SUPER_ADMIN_EDIT_RIGHTS : SUPER_ADMIN_RIGHTS;
      } else {
        selectRights = ADMIN_RIGHTS;
      }

      return selectRights.map(role => ({
        text: USER_ROLE_LABEL[role],
        value: role
      }));
    },
    isDTCNDCheckboxDisabled() {
      return (this.value.doctorLevel !== 1) && (this.value.doctorLevel !== 2);
    },
    isAutopilotCheckboxVisible() {
      return this.value.doctorLevel > 1;
    }
  },
  methods: {
    updateInputField(newValue, fieldName) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/user/update-user-modal';

.user-demo-info {
  &__level-select {
    width: 170px;
    flex-grow: 0;
  }
}
</style>
