<template>
  <v-avatar class="mr-2" size="24">
    <v-img v-if="photo" :src="photoURL" alt="Avatar" width="24" height="24" />
    <v-icon v-else>mdi-account-outline</v-icon>
  </v-avatar>
</template>

<script>
import { buildImageURL } from '@/utils';

export default {
  name: 'UserAvatar',
  props: {
    photo: {
      type: String,
      default: ''
    }
  },
  computed: {
    photoURL() {
      return buildImageURL({ photo: this.photo });
    }
  }
};
</script>
