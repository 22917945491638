<template>
  <v-data-table
    height="100%"
    v-bind="$attrs"
    :items-per-page="itemsPerPage"
    :footer-props="tableFooterProps"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>

<script>
const TABLE_FOOTER_PROPS = {
  showFirstLastPage: true,
  itemsPerPageOptions: [20, 50, 100]
};

export default {
  name: 'DataTable',
  inheritAttrs: false,
  tableFooterProps: TABLE_FOOTER_PROPS,
  props: {
    disablePagination: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 20
    }
  },
  computed: {
    tableFooterProps() {
      return {
        ...TABLE_FOOTER_PROPS,
        disablePagination: this.disablePagination
      };
    }
  }
};
</script>

<style lang="scss">
.v-data-table {
  height: calc(100% - 59px);
}
</style>
