<template>
  <div>
    <div :style="cssVars" :class="wrapperClasses">
      <div
        :tabindex="0"
        :class="['phone-number-dropdown', { open: open }]"
        @keydown="handleKeyboardNavigation"
        @click="toggleDropdown"
        @blur="closeDropdown"
        @keydown.esc="resetDropdown"
      >
        <span class="phone-number-selection">
          <span class="phone-number-country-code">
            +{{ activeCountry.dialCode }}
          </span>
          <v-icon class="ml-1">mdi-menu-down</v-icon>
        </span>
        <ul v-show="open" ref="list" class="phone-number-dropdown-list" :class="dropdownOpenDirection">
          <li
            v-for="(pb, index) in countries"
            :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
            :class="['phone-number-dropdown-item', highlightedItemClass(index)]"
            @click="choose(pb, true)"
            @mousemove="selectedIndex = index"
          >
            <strong>{{ pb.name }}</strong>
            <span>
              +{{ pb.dialCode }}
            </span>
          </li>
        </ul>
      </div>
      <input
        ref="input"
        v-model="phone"
        type="tel"
        class="phone-number-input"
        autocomplete="on"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :autofocus="false"
        :maxlength="25"
        :tabindex="0"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @keyup.enter="onEnter"
        @keyup.space="onSpace"
      />
    </div>
    <p
      v-for="(error, index) in errors"
      v-show="errors.length"
      :key="`${error}${index}`"
      class="vuetify-error px-3 mb-2 caption error--text"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { is, isEmpty } from 'ramda';
import PhoneNumber from 'awesome-phonenumber';
import COUNTRIES from '@/constants/countries';

function setCaretPosition(ctrl, pos) {
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);
  }
}

export default {
  name: 'PhoneNumberInput',
  inject: {
    form: { default: null }
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Enter a phone number',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    defaultCountry: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone: '',
      activeCountry: { iso2: '' },
      open: false,
      selectedIndex: null,
      typeToFindInput: '',
      typeToFindTimer: null,
      cursorPosition: 0,
      dropdownOpenDirection: 'below',
      dropdownPosition: {
        x: 0,
        yTop: 0,
        yBottom: 0,
      },
      errors: [],
    };
  },
  computed: {
    cssVars() {
      return {
        '--dropdown-list-left': `${this.dropdownPosition.x}px`,
        '--dropdown-list-top': `${this.dropdownPosition.yBottom}px`,
        '--dropdown-list-bottom': `${this.dropdownPosition.yTop}px`,
      };
    },
    countries: () => COUNTRIES,
    wrapperClasses() {
      return [
        'phone-number-input-wrapper',
        'body-1',
        { 'phone-number-input-wrapper--error': this.hasErrors },
        { disabled: this.disabled },
      ];
    },
    hasErrors() {
      return !isEmpty(this.errors);
    },
    phoneObject() {
      const result = PhoneNumber(this.phone, this.activeCountry.iso2).toJSON();
      Object.assign(result, {
        isValid: result.valid,
        country: this.activeCountry,
      });
      return result;
    },
    phoneText() {
      return this.phoneObject.number.input;
    },
  },
  watch: {
    value() {
      this.phone = this.value;
    },
    open(isDropdownOpened) {
      if (isDropdownOpened) {
        this.setDropdownPosition();
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
    phone(newValue, oldValue) {
      if (!this.validatePhone()) {
        this.$nextTick(() => { this.phone = oldValue; });
      } else if (newValue) {
        if (newValue[0] === '+') {
          const code = PhoneNumber(newValue).getRegionCode();
          if (code) {
            this.activeCountry = this.findCountry(code) || this.activeCountry;
          }
        }
      }
      // Reset the cursor to current position if it's not the last character.
      if (this.cursorPosition < oldValue.length) {
        this.$nextTick(() => { setCaretPosition(this.$refs.input, this.cursorPosition); });
      }
    },
  },
  created() {
    if (this.form) {
      this.form.register(this);
    }
    if (this.value) {
      this.phone = this.value.trim();
    }
  },
  mounted() {
    this.initializeCountry();
  },
  beforeDestroy() {
    if (this.form) {
      this.form.unregister(this);
    }
  },
  methods: {
    initializeCountry() {
      if (this.phone && this.phone[0] === '+') {
        const activeCountry = PhoneNumber(this.phone).getRegionCode();
        if (activeCountry) {
          this.choose(activeCountry);
          return;
        }
      }
      if (this.defaultCountry) {
        const defaultCountry = this.findCountry(this.defaultCountry);
        if (defaultCountry) {
          this.choose(defaultCountry);
          return;
        }
      }
      const fallbackCountry = this.countries[0];
      this.choose(fallbackCountry);
    },
    getCountries(list) {
      return (list || [])
        .map((countryCode) => this.findCountry(countryCode))
        .filter(Boolean);
    },
    findCountry(iso = '') {
      return this.countries.find((country) => country.iso2 === iso.toUpperCase());
    },
    highlightedItemClass(itemIndex) {
      return {
        highlighted: this.selectedIndex === itemIndex,
      };
    },
    choose(country, toEmitInputEvent = false) {
      let parsedCountry = country;
      if (typeof parsedCountry === 'string') {
        parsedCountry = this.findCountry(parsedCountry);
      }
      if (!parsedCountry) {
        return;
      }
      this.activeCountry = parsedCountry;
      this.$emit('country-changed', this.activeCountry);

      if (this.phone
        && this.phone[0] === '+'
        && this.activeCountry.iso2
        && this.phoneObject.number.national) {
        // Attach the current phone number with the newly selected country
        this.phone = PhoneNumber(this.phoneObject.number.national, this.activeCountry.iso2)
          .getNumber('international');
      }
      if (toEmitInputEvent) {
        this.$emit('input', this.phoneText, this.phoneObject);
      }
    },
    validatePhone() {
      return /^\d*$/.test(this.phone);
    },
    onInput(e) {
      if (this.hasErrors) {
        this.resetErrors();
      }
      if (!this.validatePhone()) {
        return;
      }
      this.$emit('input', this.phoneText, this.phoneObject);

      // Keep the current cursor position just in case the input reformatted
      // and it gets moved to the last character.
      if (e && e.target) {
        this.cursorPosition = e.target.selectionStart;
      }
    },
    validate() {
      this.resetErrors();

      this.rules.forEach(rule => {
        const result = is(Function, rule) ? rule(this.value) : false;

        if (is(String, result)) {
          this.errors.push(result);
        }
      });

      return isEmpty(this.errors);
    },
    resetErrors() {
      this.errors = [];
    },
    onBlur() {
      this.validate();
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    onEnter() {
      this.$emit('enter');
    },
    onSpace() {
      this.$emit('space');
    },
    focus() {
      this.$refs.input.focus();
    },
    toggleDropdown() {
      if (this.open) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },
    closeDropdown() {
      this.open = false;
    },
    openDropdown() {
      if (this.disabled) {
        return;
      }
      this.open = true;
    },
    handleKeyboardNavigation(e) {
      if (e.keyCode === 40) {
        // down arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(this.countries.length - 1, this.selectedIndex + 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop + selEle.clientHeight
          > this.$refs.list.scrollTop + this.$refs.list.clientHeight) {
          this.$refs.list.scrollTop = selEle.offsetTop
            - this.$refs.list.clientHeight
            + selEle.clientHeight;
        }
      } else if (e.keyCode === 38) {
        // up arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.countries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop < this.$refs.list.scrollTop) {
          this.$refs.list.scrollTop = selEle.offsetTop;
        }
      } else if (e.keyCode === 13) {
        // enter key
        if (this.selectedIndex !== null) {
          this.choose(this.countries[this.selectedIndex]);
        }
        this.open = !this.open;
      } else {
        // typing a country's name
        this.typeToFindInput += e.key;
        clearTimeout(this.typeToFindTimer);
        this.typeToFindTimer = setTimeout(() => {
          this.typeToFindInput = '';
        }, 700);
        // don't include preferred countries so we jump to the right place in the alphabet
        const typedCountryI = this.countries
          .findIndex((c) => c.name.toLowerCase().startsWith(this.typeToFindInput));
        if (typedCountryI >= 0) {
          this.selectedIndex = typedCountryI;
          const selEle = this.$refs.list.children[this.selectedIndex];
          const needToScrollTop = selEle.offsetTop < this.$refs.list.scrollTop;
          const needToScrollBottom = selEle.offsetTop + selEle.clientHeight
            > this.$refs.list.scrollTop + this.$refs.list.clientHeight;
          if (needToScrollTop || needToScrollBottom) {
            this.$refs.list.scrollTop = selEle.offsetTop - this.$refs.list.clientHeight / 2;
          }
        }
      }
    },
    resetDropdown() {
      this.selectedIndex = this.countries.map((c) => c.iso2).indexOf(this.activeCountry.iso2);
      this.closeDropdown();
    },
    setDropdownPosition() {
      const { top, left, bottom } = this.$el.getBoundingClientRect();
      const spaceBelow = window.innerHeight - bottom;
      const hasEnoughSpaceBelow = spaceBelow > 200;

      this.dropdownPosition = { x: left, yTop: window.innerHeight - top, yBottom: bottom };

      if (hasEnoughSpaceBelow) {
        this.dropdownOpenDirection = 'below';
      } else {
        this.dropdownOpenDirection = 'above';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-number-dropdown {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  padding: 7px;
  cursor: pointer;

  &.show {
    max-height: 300px;
    overflow: scroll;
  }
  &.open {
    background-color: #f3f3f3;
  }
  &:hover {
    background-color: #f3f3f3;
  }
}

.phone-number-selection {
  font-size: 0.8em;
  display: flex;
  align-items: center;

  .phone-number-country-code {
    color: #666;
  }
}

.phone-number-dropdown-list {
  z-index: 1;
  padding: 0;
  margin: 0;
  text-align: left;
  list-style: none;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  left: -1px;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 390px;

  &.below {
    top: var(--dropdown-list-top);
    bottom: auto;
  }
  &.above {
    top: auto;
    bottom: var(--dropdown-list-bottom);
  }
}

.phone-number-dropdown.open > .phone-number-dropdown-list {
  position: fixed;
  left: var(--dropdown-list-left);
  z-index: 10;
}


.phone-number-dropdown-arrow {
  transform: scaleY(0.5);
  display: inline-block;
  color: #666;
}
.phone-number-dropdown-item {
  cursor: pointer;
  padding: 4px 15px;

  &.highlighted {
    background-color: #f3f3f3;
  }
}

.phone-number-input {
  border: none;
  border-radius: 0 2px 2px 0;
  width: 100%;
  outline: none;
  padding-left: 7px;
}

.phone-number-input-wrapper {
  display: flex;
  text-align: left;
  min-height: 40px;
  box-sizing: border-box;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &.disabled .selection,
  &.disabled .dropdown,
  &.disabled input {
    cursor: no-drop;
  }

  &:hover {
    border-color: black;
  }

  &:focus-within {
    box-shadow: none;
    border-color: #257ace;
  }

  &--error {
    border-color: #ff5252;
    border-width: 2px;

    &:hover {
      border-color: #ff5252;
    }
  }
}
</style>
