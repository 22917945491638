import { isEmpty, pathOr, dec, over, lensIndex, toUpper, compose, join } from 'ramda';
import Compressor from 'compressorjs';

import CountriesService from '@/services/CountriesService';
import DateFormatService from '@/services/DateFormatService';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  SORTING_DIRECTIONS,
  FILE_TYPES
} from '@/constants/common';

import { PROMO_CODES_AMOUNT_TYPE } from '@/constants/promo-codes';

const getLabelFromBoolean = boolean => (boolean ? 'Yes' : 'No');

const buildImageURL = ({ photo }) => photo && `data:image/jpeg;base64,${photo}`;

const getFormattedPhone = ({ isoCode, phoneNumber }) => {
  if (isoCode && phoneNumber && !isEmpty(phoneNumber)) {
    const countryCode = CountriesService.getPhoneCode(isoCode);

    return `+${countryCode}${phoneNumber}`;
  }

  return '';
};

const createFormData = (key, value, fileName) => {
  const formData = new FormData();

  formData.append(key, value, fileName);

  return formData;
};

const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;

  a.click();

  window.URL.revokeObjectURL(url);
};

const isPdfFile = ({ type }) => type === FILE_TYPES.PDF;

const isXlsxFile = ({ type }) => type === FILE_TYPES.XLSX;

const convertStringToTitleFormat = compose(join(''), over(lensIndex(0), toUpper));

const getCurrentFullYear = () => {
  return new Date().getFullYear();
};

const getFormattedDate = date => DateFormatService.formatDate(new Date(date), 'dd/MM/yy');

const getFullMonthName = monthNumber => {
  const monthName = DateFormatService.formatDate(new Date(2020, monthNumber - 1, 1), 'MMMM');
  return convertStringToTitleFormat(monthName);
};

const formatDateFieldsInArray = (dataArray, fieldNamesArray) => {
  return dataArray.map(item => {
    const formattedDates = {};

    fieldNamesArray.forEach(fieldName => {
      const value = item[fieldName];
      formattedDates[fieldName] = value
        ? DateFormatService.formatDate(new Date(value), DEFAULT_DATE_FORMAT)
        : null;
    });

    return { ...item, ...formattedDates };
  });
};

const formatOrdersMoneyFields = ordersList => {
  return ordersList.map(order => {
    const {
      amount,
      doctorFee,
      influencerFee,
      currency,
      appliedPromoCodeAmount,
      feeCurrencyCode
    } = order;

    return currency
      ? {
          ...order,
          amount: `${currency}${amount}`,
          doctorFee: feeCurrencyCode ? `${feeCurrencyCode}${doctorFee}` : '',
          influencerFee: feeCurrencyCode ? `${feeCurrencyCode}${influencerFee}` : '',
          appliedPromoCodeAmount: appliedPromoCodeAmount
            ? `${currency}${appliedPromoCodeAmount}`
            : null
        }
      : order;
  });
};

const compareDates = (a, b) => new Date(a).getTime() - new Date(b).getTime();

const transformDataTableOptionsForRequest = (options, defaultOrder = SORTING_DIRECTIONS.DESC) => {
  const isDefaultOrderDesc = defaultOrder === SORTING_DIRECTIONS.DESC;

  const page = pathOr(DEFAULT_PAGE_NUMBER, ['page'], options);
  const size = pathOr(DEFAULT_PAGE_SIZE, ['itemsPerPage'], options);
  const sortType = pathOr(undefined, ['sortBy', 0], options);
  const sortDirection = pathOr(isDefaultOrderDesc, ['sortDesc', 0], options)
    ? SORTING_DIRECTIONS.DESC
    : SORTING_DIRECTIONS.ASC;

  return { page: dec(page), size, sortType, sortDirection };
};

const debounceCall = delay => {
  let debounceTimerId;
  return fn => {
    clearTimeout(debounceTimerId);
    debounceTimerId = setTimeout(() => fn.apply(this), delay);
  };
};

const compressImageFile = imageFile =>
  new Promise(
    (resolve, reject) =>
      new Compressor(imageFile, {
        quality: 0.7,
        maxWidth: 4096,
        maxHeight: 4096,
        success(file) {
          resolve(file);
        },
        error(error) {
          reject(error);
        }
      })
  );

const getFileType = ({ type }) => type.split('/');

const formatPromoCodeAmount = (amount, type, currency) => {
  return type === PROMO_CODES_AMOUNT_TYPE.ABSOLUTE ? `${currency}${amount}` : `${amount}%`;
};

const getErrorDescription = error => {
  return pathOr(null, ['response', 'data', 'description'], error);
};

const getFileFromUser = (multiple = false) => {
  return new Promise(resolve => {
    const input = document.createElement('input');
    input.type = 'file';

    const onFileInput = event => {
      const { files } = event.target;
      const [file] = files;
      const result = multiple ? files : file;

      resolve(result);
    };

    input.addEventListener('change', onFileInput);

    input.click();
  });
};

const formatPromoCodeOrderField = orders => {
  const orderNumbers = orders.length;

  if (!orderNumbers) {
    return '-';
  }

  return orderNumbers > 1 ? `${orderNumbers} orders` : '1 order';
};

const getPriceFormatter = currencyCode => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
};

const formatPrice = ({ currency, price }) => getPriceFormatter(currency).format(price);

const getCurrencySymbol = currencyCode =>
  getPriceFormatter(currencyCode)
    .formatToParts(0)
    .filter(({ type }) => type === 'currency')[0].value;

const taxFormatter = (taxFieldValue, defaultValue = '') =>
  taxFieldValue || taxFieldValue === 0 ? `${taxFieldValue}%` : defaultValue;

const formatNumberWithSpaces = number => {
  const valueToFormat = number || '';
  const formatter = new Intl.NumberFormat('en');

  return formatter.format(valueToFormat).replace(/,/g, ' ');
};

const deleteSpaces = string => string.replace(/\s/g, '');

export {
  getLabelFromBoolean,
  buildImageURL,
  getFormattedPhone,
  createFormData,
  downloadFile,
  formatDateFieldsInArray,
  compareDates,
  transformDataTableOptionsForRequest,
  debounceCall,
  getCurrentFullYear,
  getFullMonthName,
  getFormattedDate,
  isPdfFile,
  isXlsxFile,
  formatOrdersMoneyFields,
  compressImageFile,
  getFileType,
  formatPromoCodeAmount,
  getErrorDescription,
  getFileFromUser,
  formatPromoCodeOrderField,
  formatPrice,
  getCurrencySymbol,
  taxFormatter,
  formatNumberWithSpaces,
  deleteSpaces
};
