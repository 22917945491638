<template>
  <section class="additional-user-info" :class="{ 'update-user-form-layout': !isEditMode }">
    <div
      class="additional-user-info__personal-info personal-info pb-5"
      :class="{ 'personal-info--two-columns': isEditMode }"
    >
      <base-select
        v-for="select in $options.userSelectFields"
        :key="select.key"
        class="mb-5"
        :value="value[select.fieldName]"
        :error-messages="errorMessages[select.fieldName]"
        v-bind="select"
        @input="updateInputField($event, select)"
      />

      <base-select
        v-if="isSelectStateVisible"
        :items="countryStates.US"
        item-value="code"
        item-text="title"
        class="mb-5"
        :value="value.state"
        label="State"
        @input="updateInputField($event, { fieldName: 'state' })"
      />

      <base-text-field
        v-for="textField in $options.userAdditionalInfoTextFields"
        :key="textField.key"
        class="mb-5"
        :value="value[textField.fieldName]"
        v-bind="textField"
        @change="updateInputField($event, textField)"
      />

      <base-text-field
        v-if="!isEditMode"
        class="mb-5"
        :value="value.universkinLink"
        label="Universkin link"
        @change="updateInputField($event, { fieldName: 'universkinLink' })"
      />

      <base-text-field
        v-if="isWebshopUser"
        class="mb-5"
        type="number"
        label="Hourly rate *"
        :value="value.consultationCost"
        :rules="[$options.validationRules.REQUIRED]"
        @change="updateInputField($event, { fieldName: 'consultationCost' })"
      />

      <phone-number-input
        v-model="privatePhone"
        :default-country="value.privatePhoneIso"
        placeholder="Personal Mobile phone number"
        class="mb-5"
        @blur="updatePhoneField('privatePhone')"
        @country-changed="updatePhoneIsoField($event, 'privatePhoneIso')"
      />

      <phone-number-input
        v-model="officePhone"
        :default-country="value.officePhoneIso"
        :rules="[$options.validationRules.REQUIRED]"
        placeholder="Office phone number"
        class="mb-5"
        @blur="updatePhoneField('officePhone')"
        @country-changed="updatePhoneIsoField($event, 'officePhoneIso')"
      />

      <base-text-field
        v-for="(secondaryEmail, index) in value.secondaryEmails"
        :key="index"
        class="mb-5"
        :value="secondaryEmail"
        label="Secondary email address"
        :rules="secondaryEmailFieldRules"
        append-outer-icon="mdi-trash-can-outline"
        @change="updateElementInArrayField($event, index, $options.secondaryEmailsField)"
        @click:append-outer="deleteElementFromArrayField(index, $options.secondaryEmailsField)"
      />

      <v-icon
        class="d-flex justify-start light-blue--text text--darken-4 mb-5"
        @click="addElementToArrayField($options.secondaryEmailsField)"
      >
        mdi-plus
      </v-icon>
    </div>
  </section>
</template>

<script>
import SelectFieldConfiguration from '@/models/input-models/SelectFieldConfiguration';
import TextFieldConfiguration from '@/models/input-models/TextFieldConfiguration';
import PhoneNumberInput from '@/components/common/PhoneNumberInput.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';
import BaseTextField from '@/components/common/BaseTextField.vue';

import { VALIDATION_RULES, COUNTRY_SELECT_ITEMS, LANGUAGE_SELECT_ITEMS } from '@/constants/common';
import { COUNTRY_STATE } from '@/constants/countries';

const USER_SELECT_FIELD_NAMES = {
  LOCALE: 'locale',
  COUNTRY: 'country'
};

const USER_SELECT_FIELDS = [
  new SelectFieldConfiguration({
    fieldName: USER_SELECT_FIELD_NAMES.LOCALE,
    label: 'Language *',
    rules: [VALIDATION_RULES.REQUIRED],
    items: LANGUAGE_SELECT_ITEMS
  }),
  new SelectFieldConfiguration({
    fieldName: USER_SELECT_FIELD_NAMES.COUNTRY,
    label: 'Country *',
    rules: [VALIDATION_RULES.REQUIRED],
    items: COUNTRY_SELECT_ITEMS
  })
];

const USER_ADDITIONAL_INFO_TEXT_FIELDS = [
  new TextFieldConfiguration({
    fieldName: 'doctorSlug',
    label: 'URL suffix *',
    rules: [VALIDATION_RULES.REQUIRED]
  }),
  new TextFieldConfiguration({
    fieldName: 'customSMSLimit',
    label: 'Custom SMS limit *',
    type: 'number',
    rules: [VALIDATION_RULES.REQUIRED]
  }),
  new TextFieldConfiguration({
    fieldName: 'personalPage',
    label: 'Personal page'
  })
];

const SECONDARY_EMAILS_FIELD_NAME = 'secondaryEmails';

const LOGISTIC_CENTER_NOT_SUPPORTED_COUNTRY_ERROR = 'Country is not supported by Logistic centers';

export default {
  name: 'UserAdditionalInfo',
  components: { BaseTextField, BaseSelect, PhoneNumberInput },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    isWebshopUser: {
      type: Boolean,
      default: false
    },
    shouldShowLogisticCenterCountryError: {
      type: Boolean,
      default: false
    },
    isSelectStateVisible: {
      type: Boolean,
      default: false
    }
  },
  validationRules: VALIDATION_RULES,
  userSelectFields: USER_SELECT_FIELDS,
  userAdditionalInfoTextFields: USER_ADDITIONAL_INFO_TEXT_FIELDS,
  secondaryEmailsField: SECONDARY_EMAILS_FIELD_NAME,
  data() {
    return {
      privatePhone: '',
      officePhone: '',
      secondaryEmailFieldRules: [VALIDATION_RULES.EMAIL],
      countryStates: COUNTRY_STATE
    };
  },
  computed: {
    errorMessages() {
      return {
        [USER_SELECT_FIELD_NAMES.COUNTRY]: this.shouldShowLogisticCenterCountryError
          ? [LOGISTIC_CENTER_NOT_SUPPORTED_COUNTRY_ERROR]
          : []
      };
    }
  },
  watch: {
    value(newValue) {
      if (newValue.privatePhone !== this.privatePhone) {
        this.privatePhone = newValue.privatePhone || '';
      }

      if (newValue.officePhone !== this.officePhone) {
        this.officePhone = newValue.officePhone || '';
      }
    }
  },
  methods: {
    updateInputField(newValue, { fieldName }) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    },
    addElementToArrayField(fieldName, defaultValue = '') {
      this.updateInputField([...this.value[fieldName], defaultValue], { fieldName });
    },
    updateElementInArrayField(newValue, index, fieldName) {
      const arrayFieldCopy = [...this.value[fieldName]];

      arrayFieldCopy.splice(index, 1, newValue);

      return this.updateInputField(arrayFieldCopy, { fieldName });
    },
    deleteElementFromArrayField(index, fieldName) {
      const arrayFieldCopy = [...this.value[fieldName]];

      arrayFieldCopy.splice(index, 1);

      return this.updateInputField(arrayFieldCopy, { fieldName });
    },
    updatePhoneField(fieldName) {
      this.updateInputField(this[fieldName], { fieldName });
    },
    updatePhoneIsoField({ iso2 }, fieldName) {
      this.updateInputField(iso2, { fieldName });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/user/update-user-modal';

.personal-info {
  &--two-columns {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
  }
}
</style>
